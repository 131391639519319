<template>
  <div>
    <van-loading @touchmove.prevent v-show="isLoading" vertical color="#1989FA" class="editor-loading"> </van-loading>
    <div id="editor-page">
      <preview-box :htmlStr="docToSave" @closePreview="exitPreview" v-if="showPreviewModal" />
      <div class="ana-wrapper" v-if="showAnalysisBox">
        <div class="bg" @click.exact="hideAnalysis"></div>
        <div class="editor-ana">
          <ana-box />
        </div>
      </div>
      <div class="styled-dropdown-popup" v-show="showMoreMenu" @click="hideMoreMenuPopup">
        <div class="more-menu-dropdown">
          <div class="menu" @click.stop="preview">预览</div>
          <div class="menu" @click="createCase" v-if="canCreateCase">建案</div>
          <div class="menu" @click="showAnalysis">分析</div>
          <div class="menu" v-if="canDelete" @click="onDeleteMyDocument">删除</div>
        </div>
      </div>
      <!-- 左侧面板 -->
      <left-panel
        ref="leftPanel"
        :style="leftPanelStyle"
        :defaultGroupId="groupId"
        :defaultDocId="documentId"
        :caseInfo="caseBasic"
        @useNewDocData="switchToDocInCurrGroup"
        @setGroupEntry="setDocGroup"
        @hideLeftPanel="handelHideLeftPanel"
      />
      <!-- 右侧面板 -->
      <!-- <right-panel :style="rightPanelStyle"
                 @hideRightPanel="handelHideRightPanel" /> -->
      <div id="editor-header" @touchstart.stop="ontouchstart" @touchmove.stop="ontouchmove" @touchend.stop="ontouchend">
        <!-- 顶部面板 -->
        <top-panel
          v-show="!inEditing"
          ref="topPanel"
          :docGroupId="docGroupId"
          :caseId="caseId"
          :documentName="documentName"
          :defaultGroupId="receivedGroupId"
          :defaultDocumentId="defaultDocumentId"
          :activeDoc="docObject"
          @showAna="showAnalysis"
          @back="back"
          @toggleMoreMenu="toggleMoreMenuPopup"
          @setCreateCaseDoc="setCreateCaseDocText"
          @updateDoc="updateDocDataSilent"
          @getDocData="getDocumentData"
          @selectDoc="onDocGroupSelectDoc"
          @showMask="showMask = true"
          @hideTopPanel="hideTopPanelAction"
          @askSave="autoSave"
        />
        <div class="full" v-show="inEditing">
          <div class="toolbar-btn">
            <div class="iconfont icon-back" @click="back"></div>
          </div>
          <div class="toolbar-btn">
            <div class="iconfont icon-editor-bold" @touchend="setBold"></div>
          </div>
          <div class="toolbar-btn">
            <div class="iconfont icon-editor-italic" @touchend="setItalic"></div>
          </div>
          <div class="toolbar-btn">
            <div class="iconfont icon-editor-underline" @touchend="setUnderline"></div>
          </div>
          <div class="toolbar-btn">
            <div @touchend="setFontSize">
              <i class="iconfont icon-editor-font-size"></i>
            </div>
          </div>
          <div class="toolbar-btn" @touchend="redo">
            <i class="iconfont icon-editor-redo"></i>
          </div>
          <div class="toolbar-btn" @touchend="undo">
            <i class="iconfont icon-editor-undo"></i>
          </div>
          <!-- <div class="toolbar-btn"
             @click="preview">
          <i class="iconfont icon-Azhi"></i>
        </div> -->
          <div class="toolbar-btn" @click="save">
            <i class="iconfont icon-editor-save"></i>
          </div>
        </div>
      </div>

      <div class="editor-container" @touchstart.stop="onEtouchstart" @touchmove.stop="onEtouchmove" @touchend.stop="onEtouchend">
        <div class="mask" v-show="showMask" @touchstart.stop="onMaskTouchstart" @touchmove.stop="onMaskTouchmove" @touchend.stop="onMaskTouchend" @click="hideMask"></div>
        <quill-editor
          ref="quillEditor"
          :style="editorWrapperStyle"
          :content="content"
          :options="editorOption"
          @change="onEditorChange($event)"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
        >
          <!-- 编辑器工具栏 -->
          <div id="editor-toolbar" slot="toolbar"></div>
        </quill-editor>
        <div v-show="focusBtn.show" :style="`position: relative;`">
          <van-button class="focus-btn" type="info" size="small" @click="insert">插入</van-button>
        </div>
        <suggestion-panel v-if="showSuggestionPanel" :height="suggestionPanelHeight" @useNewText="useSuggestion" />
      </div>

      <van-action-sheet v-model="showFontSizePicker" class="editor-font-size-selector" :actions="fontSizeActions" @select="onSelectFontSize" />
      <van-popup v-model="centerDialogVisible" position="right" :overlay="false">
        <div class="reason-picker-wrapper" :style="reasonPickerStyle">
          <div class="change-notice-pikcer">
            <reason-picker ref="reasonPicker" :showConfirmBtn="true" @change="handleReasonChange" @confirmSelect="handleReasonSelect" />
            <div class="parse-reason-sec" v-if="caseReasonChange && caseReasonChange.caseReasonMsg">
              <div class="title">请选择案件适用的案由：</div>
              <div class="picker-wrapper">
                <van-radio-group v-model="pickedParseReason">
                  <van-radio :name="caseReasonChange.caseCaseReason">{{ caseReasonChange.caseCaseReason }}</van-radio>
                  <van-radio :name="caseReasonChange.htmlCaseReason">{{ caseReasonChange.htmlCaseReason + '（当前文书）' }}</van-radio>
                </van-radio-group>
              </div>
            </div>
            <div class="rec-reason-sec" v-if="caseReasonChange.recommend && caseReasonChange.recommend.length">
              <div class="title">请选择案件适用的案由：</div>
              <div class="picker-wrapper">
                <van-radio-group v-model="pickedRecommendReason">
                  <van-radio v-for="r in caseReasonChange.recommend" :key="r" :name="r">{{ r }} </van-radio>
                </van-radio-group>
              </div>
            </div>
            <div class="litigant-sec" v-if="htmlChange.changeLitigants && htmlChange.changeLitigants.length && htmlChange.htmlCode === 1">
              <div class="title">请选择案件当事人：</div>
              <div class="picker-wrapper">
                <van-checkbox-group v-model="pickedLitigant" class="no-flex">
                  <div v-for="(p, i) in allLitigants" :key="i">
                    <van-checkbox :name="calcLitigantId(p)">{{ calcLitigantName(p) }}</van-checkbox>
                  </div>
                </van-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import Qs from 'qs'

import { mapState, mapMutations, mapGetters } from 'vuex'
import Quill from './const/quill.js'

import TopPanel from './components/topPanel'
import LeftPanel from './components/leftPanel'
// import RightPanel from './components/rightPanel'
import SuggestionPanel from './components/suggestionPanel'
import PreviewBox from './components/previewBox'
import AnaBox from '@/pages/Ana'
import ReasonPicker from '@components/reasonPicker'
import compressHtml from './util/compress-html'

import goBackMixin from '@mixins/goBack'
import touchHandler from '@mixins/touchHandler'
import editorTouch from './mixins/editorTouch'
import editorToolbar from './mixins/editorToolbar'
import switchMixin from './mixins/switch'
import editorHelper from './mixins/editorHelper'
import reasonHandler from './mixins/reasonHandler'
import docGroupHandler from './mixins/docGroupHandler'
import componentStyle from './mixins/componentStyle'

import { debounce } from '@/common/utils'
import { fontSizeArr, editorOption, nomalFontSizeInPX } from './const'
const Size = Quill.import('attributors/style/size')
export default {
  name: 'editor-page',
  components: {
    LeftPanel,
    TopPanel,
    // RightPanel,
    PreviewBox,
    SuggestionPanel,
    AnaBox,
    ReasonPicker,
  },
  mixins: [goBackMixin, touchHandler, editorTouch, editorToolbar, editorHelper, reasonHandler, docGroupHandler, componentStyle, switchMixin],
  data() {
    return {
      title: '编辑器',
      content: '',
      startPoint: { x: 0, y: 0 },
      currentPoint: { x: 0, y: 0 },
      editorOption: editorOption,
      // 选中的文本起始位置和长度
      selection: { index: 0, length: 0 },
      // 选中文本的样式
      selectionFormat: {},
      // 当前文书名称
      documentName: '',
      // 案件基本信息
      caseBasic: { caseName: '', procedureSubject: '', caseStatus: 'NORMAL' },
      container: () => document.body,
      // 是否在滑动顶部编辑栏
      isTouching: false,
      // 是否在加载数据
      isLoading: false,
      // vant-list 下拉控制变量
      inPullDown: false,
      // 是否在编辑模式
      inEditing: false,
      // 是否在设置样式
      inStyling: false,
      docGroupId: null,
      documentId: null,
      myDocumentId: null,
      // 当事人id
      litigantId: null,
      // 文书组id
      groupId: '',
      // 接收到的文书组id
      receivedGroupId: '',
      // 搜索建议面板高度
      suggestionPanelHeight: 88,
      // 中断搜索
      breakSuggest: false,
      index: 0,
      // 触发法条关键词
      lawKwStr: '',
      strIndex: 0,
      replaceLabel: '',
      isNormalCase: true,
      parsedReasons: [],
      showReasonPicker: false,
      // 用于接收案由选择器传过来的案由对象
      reason: null,
      selectedReasonName: '',
      cacheLawPushWordCount: 0,
      // 法条推荐缓存
      cachedLawPushData: [],
      centerDialogVisible: false,
      editorLoading: true,
      docObject: {
        documentId: null,
        html: '',
        groupId: null,
        documentName: '',
        myDocumentId: null,
        documentRecordId: null,
      },
      module: false,
      focusBtn: {
        show: false,
        top: 0,
        left: 0,
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
    ...mapState('user', ['userInfo']),
    ...mapState('ana', ['focusText']),
    ...mapState('editor', ['customerHtml', 'lawList', 'companyList', 'customerList', 'currentDocList', 'waitChangeTab', 'inChangeDocProcess', 'isCurrentDocumentUnsaved']),
    defaultDocumentId() {
      return this.documentId || this.myDocumentId
    },
    rootFontSize() {
      return parseFloat(document.documentElement.style.fontSize.replace(/px/g, ''))
    },
    fontSizeActions() {
      return fontSizeArr
    },
    ptPr() {
      return this.rootFontSize / 37.5
    },
    // quill实例
    editor() {
      return this.$refs.quillEditor && this.$refs.quillEditor.quill
    },
    caseId() {
      const id = this.$route.params.caseId
      return id ? parseInt(id) : null
    },
    caseDocumentId() {
      return this.$route.query.caseDocumentId
    },
    // 可以保存到服务器的文档数据
    docToSave() {
      // console.log('doctosave---')
      // console.log(this.content)
      const _me = this
      const unSizeText = this.content.replace(/\d+px+|(\d+\.\d+px)/g, (target) => {
        const size = target.replace(/px/g, '')
        return Math.round(parseFloat(size) / _me.ptPr) + 'px'
      })
      return unSizeText
    },
    // 编辑器纯文字内容 （去除了html标签）
    docPlain() {
      // console.log('docplain')
      return this.content.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
    },
    // 是否可以新建项目
    canCreateCase() {
      // caseStatus 有两种值 HIDE NORMAL
      if (this.caseBasic.caseStatus && this.caseBasic.caseStatus === 'HIDE') {
        return true
      } else {
        return false
      }
    },
    // 只有 myDocumentId有值才可以删除
    canDelete() {
      if (this.myDocumentId) {
        return true
      } else {
        return false
      }
    },
    // 检索建议数据为空
    isSuggestionDataEmpty() {
      if (!this.customerList.length && !this.companyList.length && !this.lawList.length) {
        return true
      } else {
        return false
      }
    },
    // 是否显示解析的案由候选列表
    showSuggestreasonList() {
      if (this.parsedReasons && this.parsedReasons.length) {
        return true
      } else {
        return false
      }
    },
    // 法条关键词替换
    lawKwReplaceVal() {
      // console.log('发条关键词替换')
      if (this.lawKwStr && this.lawKwStr !== '') {
        return this.calcReplaceVal(this.lawKwStr)
      } else {
        return ''
      }
    },
    // 当前文档-参数
    docParams() {
      return {
        caseId: this.caseId,
        documentId: this.docObject.documentId,
        html: this.docToSave,
        groupId: this.groupId || this.docObject.groupId,
        documentName: this.docObject.documentName,
        myDocumentId: this.docObject.myDocumentId || null,
        documentRecordId: this.docObject.documentRecordId || null,
        choiceLitigantList: this.pickedLitigantForSave,
      }
    },
  },
  methods: {
    ...mapMutations('editor', ['setTime', 'setAnaText', 'setInSearching', 'setCustomerHtml', 'setLawList', 'setCustomerList', 'setCompanyList', 'setEditorKV']),
    ...mapMutations('createCase', ['setDoc']),
    ...mapMutations('ana', ['SET_FOCUS_TEXT']),
    // 插入争议焦点
    insert() {
      const text = this.focusText
      this.insertText(this.focusBtn.index, text)
      // 清空内容，隐藏按钮
      this.SET_FOCUS_TEXT('')
      this.focusBtn.show = false
    },
    // 编辑器常用监听 --start--
    // 编辑器失焦时
    onEditorBlur(quill) {
      // this.inEditing = true
      this.focusBtn.show = false
    },
    // 编辑器聚焦时
    onEditorFocus(quill) {},
    // 编辑器初始化完毕时
    onEditorReady(quill) {},
    // 编辑器改动时
    onEditorChange({ quill, html }) {
      this.content = html
      this.autoSuggest()
      const len = quill.getLength()
      if (this.cacheLawPushWordCount === 0 && len >= 200) {
        // console.log('大于200')
        this.cacheLawPushList(len)
      } else if (len - this.cacheLawPushWordCount >= 50) {
        // console.log('大于50')
        this.cacheLawPushList(len)
      } else {
        // console.log('不缓存', this.cacheLawPushWordCount, len)
      }
      // console.log('editor change over')
    },
    // 检查缓存是否命中关键词
    checkCacheHit(str) {
      let has = false
      this.cachedLawPushData.forEach((element) => {
        has = element.title.indexOf(str) !== -1
      })
      return has
    },
    checkExistInLitigantList(arr, obj) {
      let exist = false
      arr.forEach((element) => {
        if (obj.litigantType === element.litigantType && obj.name === element.name && obj.procedureSubjectEnum === element.procedureSubjectEnum) {
          exist = true
        }
      })
      return exist
    },
    // 缓存法条
    cacheLawPushList(len) {
      // console.log('len', len)
      this.cacheLawPushWordCount = len
      const text = this.docPlain
      // console.log('text', text)
      this.$http
        .post(`${this.$pydataBase}/api/nvi/law_push`, Qs.stringify({ text, case_id: this.caseId }))
        .then((res) => {
          const {
            data: { data },
          } = res
          // 将数据整理成 三个层级结构
          if (data.length > 0) {
            this.cachedLawPushData = data
          }
          // console.log('数据整理三个层级结构701', data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取历史当事人
    getCustomer(keyword) {
      this.setInSearching(true)
      this.$axios
        .get(`${this.$base}/lts/customer/vagueQueryCustomer?name=${keyword}`)
        .then((res) => {
          this.setInSearching(false)
          const {
            data: { data },
          } = res
          this.setCustomerList(data)
          if (data.length > 0) {
            this.openSuggestionPanel()
          }
        })
        .catch((err) => {
          this.setInSearching(false)
          Promise.reject(err)
        })
    },
    // 获取工商信息
    getBusiness(keyword) {
      this.$axios
        .get(`${this.$base}/management/business?name=${keyword}`)
        .then((res) => {
          this.setInSearching(false)
          if (res.status === 200) {
            const { data } = res
            if (data && data !== '无结果' && data.length > 0) {
              this.setCompanyList(data)
              this.openSuggestionPanel()
            }
          } else {
            console.log('接口未返回正确状态')
          }
        })
        .catch((err) => {
          this.setInSearching(false)
          Promise.reject(err)
        })
    },

    // 法条联想
    getLawEntry(lawKw) {
      if (this.cachedLawPushData.length) {
        this.setCustomerList([])
        this.setCompanyList([])
        // 检查缓存是否命中 如果没有命中则显示加载提示 拿到数据后再进行缓存并展示
        const cacheKw = lawKw.replace(new RegExp(this.lawKwReplaceVal, 'g'), '')
        const hit = this.checkCacheHit(cacheKw)
        if (hit && this.cachedLawPushData.length) {
          this.setLawList(this.cachedLawPushData)
          this.openSuggestionPanel()
        } else {
          this.setInSearching(true)
        }
        this.$http.post(`${this.$pydataBase}/api/nvi/law_push`, Qs.stringify({ text: this.docPlain, word: lawKw })).then((res) => {
          this.setInSearching(false)
          const {
            data: { data },
          } = res
          if (data && data.length > 0) {
            /**
             ** 2019-07-31 逻辑更改为 命中缓存的时候 不更新store只更新缓存 不命中的时候更新
             */
            this.cachedLawPushData = data
            if (!(hit && this.cachedLawPushData.length)) {
              this.setLawList(this.cachedLawPushData)
              this.openSuggestionPanel()
            }
          }
        })
      }
    },
    // 清空 store/建议数据
    clearSuggestionStoreData() {
      this.setCustomerList([])
      this.setCompanyList([])
      this.setLawList([])
    },
    // 获取案件基本信息
    async getCaseBasic() {
      await this.$axios({
        method: 'get',
        url: `${this.$base}/document/caseMore/queryCaseBasic`,
        params: {
          caseId: this.caseId,
        },
      })
        .then((res) => {
          if (res.data && res.data.data) {
            const { data } = res.data
            if (data) {
              this.caseBasic = data
              this.caseStatus = data.caseStatus
            }
          }
        })
        .catch((err) => {
          this.$notify('获取案件信息失败')
          Promise.reject(err)
        })
    },
    /*  保存文书
     * @param displayUpdate-是否更新共享信息
     */
    saveDocument(docData, displayUpdate = 0) {
      this.clearSuggestionStoreData()
      this.closeSuggestionPanel()
      const loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: 'spinner',
        message: '正在保存文档',
        mask: true,
      })
      docData.displayUpdate = displayUpdate
      this.$axios({
        method: 'post',
        url: `${this.$base}/document/basicDocuments/saveDocument`,
        data: Qs.stringify(docData),
      })
        .then((res) => {
          // 清除加载
          loading.clear()
          // 设置当前文档未保存状态为 false
          this.setEditorKV({ key: 'isCurrentDocumentUnsaved', val: false })
          this.refreshNativeWrit()
          this.$toast.success('保存文档成功')
          // 刷新左侧面板-模板数据
          if (docData.documentId) {
            this.$refs.leftPanel.updateTemplateState(this.documentId)
          }
          if (this.waitChangeTab) {
            if (this.$refs.topPanel) {
              this.$refs.topPanel.updateWaitDoc()
            }
          }
          // 如果案由数据存在 则需要重新获取案件信息以更新案由名称并清空案由相关数据
          if (this.reason) {
            this.getCaseBasic()
            this.reason = null
            this.parsedReasons = []
            this.selectedReasonName = ''
          }
        })
        .catch((err) => {
          loading.clear()
          this.$toast.fail('操作失败')
          Promise.reject(err)
        })
    },
    // 注册quill编辑器自定义字体
    registerQuillFontSize() {
      const fontArr = []
      fontSizeArr.forEach((elem) => {
        const size = `${elem.value * (this.rootFontSize / 37.5)}pt`
        fontArr.push(size)
      })

      nomalFontSizeInPX.forEach((elem) => {
        const size = `${elem * (this.rootFontSize / 37.5)}px`
        fontArr.push(size)
      })

      Size.whitelist = fontArr
      Quill.register(Size, true)
    },
    // 编辑文档
    editDoc() {
      this.inEditing = true
      this.editor.enable()
    },
    // 触发左侧面板菜单
    onLeftMenuClick() {
      this.showLeftPanel = !this.showLeftPanel
    },
    // 下滑
    onPullDown() {
      setTimeout(() => {
        this.inPullDown = false
      }, 100)
    },
    // A4纸张预览
    preview() {
      this.showPreviewModal = true
    },

    // 设置编辑器内容
    setNewContent(payload) {
      this.myDocumentId = null
      const { disableEditor } = payload
      if (disableEditor) {
        this.updateEditorContent(payload.html, disableEditor)
      } else {
        this.updateEditorContent(payload.html)
      }
      this.documentId = payload.documentId
      this.documentName = payload.documentName
    },
    useNewDocumentEntry(payload) {
      this.myDocumentId = null
      this.getDocLastEdited(this.caseId, payload.documentId)
    },
    /**
     * * 更新编辑器内容
     * @param payload :documentId
     **/
    updateDocData(payload) {
      // 关闭左上角菜单
      this.hideMoreMenuPopup()
      // 先清除myDocumentId
      this.myDocumentId = null
      // 设置文书信息
      this.documentId = payload.documentId
      this.litigantId = payload.litigantId
      const { disableEditor } = payload
      const useDefaultGroupId = payload.useDefaultGroupId || false
      const renderCondition = this.isNormalCase ? 2 : 1
      this.getDocLastEdited(this.caseId, this.documentId, this.litigantId, renderCondition, disableEditor, useDefaultGroupId)
    },
    updateDocDataSilent(payload) {
      // 关闭左上角菜单
      this.hideMoreMenuPopup()
      // 先清除myDocumentId
      this.myDocumentId = null
      // 设置文书信息
      this.documentId = payload.documentId
      this.litigantId = payload.litigantId
      const { disableEditor } = payload
      const useDefaultGroupId = false
      const renderCondition = this.isNormalCase ? 2 : 1
      this.getDocLastEdited(this.caseId, this.documentId, this.litigantId, renderCondition, disableEditor, useDefaultGroupId)
    },
    // 更新编辑器 html内容
    updateEditorContent(str, disableEditor = true) {
      // console.log('更新编辑内容')
      // 防止弹出键盘
      if (disableEditor) {
        // console.log('disableeditor真')
        this.editor.disable()
      }
      // console.log('seteditorkv')
      this.setEditorKV({ key: 'inChangeDocProcess', val: true })
      // console.log('set完成')
      setTimeout(() => {
        // console.log('进入settimeout')
        // console.log(str)
        // this.content = str
        const compressedHtml = compressHtml(str)
        this.content = this.transformToRenderDoc(compressedHtml)
        this.resetEditorScroll()
        // console.log('settimeout晚餐')
      }, 20)
      setTimeout(() => {
        // 清除历史记录
        this.editor.history.clear()
        this.editor.blur()
        this.editor.enable()
        this.setEditorKV({ key: 'inChangeDocProcess', val: false })
        this.setEditorKV({ key: 'isCurrentDocumentUnsaved', val: false })
        setTimeout(() => {
          // this.$nextTick(() => {
          this.isLoading = false
          // })
        }, 400)
      }, 30)
    },
    // 启用编辑器并更新状态为编辑模式
    enableEditor() {
      this.editor.enable()
      this.inEditing = true
    },
    // 禁用编辑器并更新状态为阅读模式
    disableEditor() {
      this.editor.blur()
      this.editor.disable()
      // this.inEditing = false
    },
    // 获取我的文书
    getMyDocument(caseId, myDocumentId) {
      this.$axios({
        method: 'post',
        url: `${this.$base}/document/basicDocuments/addInformationAndParseHtml`,
        data: {
          caseId,
          myDocumentId,
          renderCondition: 2,
        },
      })
        .then((res) => {
          const { groupId, documentId, litigantId, documentRecordId, myDocumentId, modelId, name, modelVoList } = res.data.data
          const doc = {
            groupId,
            documentId,
            litigantId,
            documentRecordId,
            myDocumentId,
            modelId,
            documentName: name,
            dataType: 'INSERT',
            html: modelVoList[0].html,
          }
          console.log('我的文书获取完成')
          this.docObject = doc
          if (groupId) {
            this.receivedGroupId = groupId.toString()
          }
          // setTimeout(() => {
          //   console.log('我的文书异步')
          //   this.notifyTopPanelMatchDoc()
          // }, 50)
        })
        .catch((err) => {
          this.$notify('获取相关文书失败')
          Promise.reject(err)
        })
    },
    // 获取用户最后编辑的文书
    getDocLastEdited(caseId, documentId, litigantId = null, renderCondition = 2, disableEditor = false, useDefaultGroupId = false) {
      console.log('获取用户最后编辑的文字')
      this.isLoading = true
      this.$axios({
        method: 'post',
        url: `${this.$base}/document/basicDocuments/addInformationAndParseHtml`,
        data: {
          caseId,
          documentId,
          litigantId,
          renderCondition,
        },
      })
        .then((res) => {
          this.cacheLawPushWordCount = 0
          const { modelVoList, groupId } = res.data.data
          if (groupId && useDefaultGroupId) {
            this.receivedGroupId = groupId.toString()
          }
          if (modelVoList && modelVoList.length) {
            const { name } = res.data.data
            const { html } = modelVoList[0]
            if (html && name) {
              this.documentName = name
              this.updateEditorContent(html, disableEditor)
            } else {
              this.$notify('该文档没有可用数据')
            }
          } else {
            this.$notify('该文书数据为空')
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.$notify('获取文书数据失败')
          Promise.reject(err)
        })
    },
    // 退出预览模式
    exitPreview() {
      this.showPreviewModal = false
    },
    resetEditorScroll() {
      const editorContainer = document.querySelector('.ql-container.ql-snow')
      const editorElem = editorContainer.firstElementChild
      editorElem.scrollTop = 0
    },
    // 设置新建项目文本
    setCreateCaseDocText() {
      console.log('set create case doc text')
      this.setDoc(this.docPlain)
      this.$dialog
        .confirm({
          message: '使用当前文书新建项目',
        })
        .then(() => {
          const path = '/createCase'
          const query = {
            caseId: this.caseId,
          }
          this.$router.push({ path, query })
        })
        .catch((err) => {
          this.setDoc('')
          Promise.reject(err)
        })
    },
    back() {
      if (this.$route.query.fromAna) {
        this.$router.back()
      } else {
        window.goback()
      }
    },
    // 检查搜索建议是否为空数据 数据则关闭面板
    checkEmptyData() {
      if (this.isSuggestionDataEmpty) {
        this.closeSuggestionPanel()
      }
    },
    /**
     * * 改变特殊案件的状态 然后编辑案件 以实现新建项目的目的
     * * 新建项目之前将当前文书内容存到store 到新建项目页面用来分析案由
     */
    changeCaseStatusThenEditCase() {
      this.$axios
        .post(`${this.$base}/lts/case/updateCaseStatusByPost?caseId=${this.caseId}`)
        .then(({ data }) => {
          if (Number(data.code) === 200) {
            const path = '/createCase'
            const query = { caseId: this.caseId, useDoc: 'YES' }
            this.$router.push({ path, query })
          } else {
            this.$notify('更新案件状态失败')
          }
        })
        .catch((err) => {
          this.$notify('更新案件状态失败')
          Promise.reject(err)
        })
    },
    // 新建项目
    createCase() {
      if (this.canCreateCase) {
        this.setDoc(this.docPlain)
        this.$dialog
          .confirm({
            message: '是否使用当前文书新建项目',
          })
          .then(() => {
            this.changeCaseStatusThenEditCase()
          })
          .catch((err) => {
            this.setDoc('')
            Promise.reject(err)
          })
      } else {
        if (this.documentName !== '民事起诉状') {
          this.$notify('暂时仅支持 民事起诉状 新建项目')
        } else {
          this.$notify('暂不支持该文书新建项目')
        }
      }
    },
    // 删除我的文书操作
    onDeleteMyDocument() {
      if (this.myDocumentId) {
        this.$dialog
          .confirm({
            title: '删除当前文书',
            message: '此操作不可恢复',
            confirmButtonText: '删除',
            cancelButtonText: '取消',
          })
          .then(() => {
            this.deleteMyDocument()
          })
          .catch(() => {
            console.log('取消')
          })
      } else {
        this.$notify('当前文书不允许删除')
      }
    },
    // 删除文书
    deleteMyDocument() {
      this.$axios
        .delete(`${this.$base}/new-design/catalog/deleteMyDocument/${this.myDocumentId}`)
        .then((res) => {
          this.$notify('删除成功 即将返回')
          this.refreshNativeWrit()
          this.back()
        })
        .catch((err) => {
          this.$notify('删除失败')
          Promise.reject(err)
        })
    },
    // 准备文书数据
    prepareDocData() {
      let doc = {}
      if (this.myDocumentId) {
        doc = {
          myDocumentId: this.myDocumentId,
          caseId: parseInt(this.caseId),
          html: this.docToSave,
          documentName: this.documentName,
        }
        this.saveDocument(doc)
      } else {
        const arr = this.currentDocList.filter((item) => item.documentId === parseInt(this.documentId, 0))
        const groupId = arr.length ? arr[0].groupId : null
        doc = {
          documentId: this.documentId,
          caseId: parseInt(this.caseId),
          html: this.docToSave,
          documentName: this.documentName,
          groupId,
        }
      }
      return doc
    },
    // 弹出提示 是否保存
    alertSave() {
      this.$dialog
        .confirm({
          title: '是否保存当前文书',
          message: `《${this.documentName}》`,
        })
        .then(() => {
          // on confirm
          const doc = this.prepareDocData()
          this.saveDocument(doc, 1)
        })
        .catch(() => {
          // on cancel
          if (this.waitChangeTab) {
            if (this.$refs.topPanel) {
              this.$refs.topPanel.updateWaitDoc()
            }
          }
        })
    },
    autoSave() {
      this.save()
    },
    calcLitigantName(p) {
      return p.litigantType ? p.litigantType + ' - ' + p.name : p.name
    },
    calcLitigantId(p) {
      return p.procedureSubjectEnum + ' - ' + p.name
    },
    setPickedLitigant(list) {
      const arr = []
      list.forEach((item) => {
        const { htmlLitigantName, procedureSubjectEnum, litigantType } = item
        if (htmlLitigantName && htmlLitigantName.length) {
          htmlLitigantName.forEach((item) => {
            const p = {
              name: item,
              procedureSubjectEnum,
              litigantType,
            }
            const name = this.calcLitigantId(p)
            arr.push(name)
          })
        }
      })
      this.pickedLitigant = arr
      console.log(this.pickedLitigant, 'pickedLitigant')
    },
    // 通过接口判断文书内容是否改动并做相应的处理
    handleHtmlChangeV2(item) {
      this.isLoading = true
      this.$axios
        .post(`${this.$base}/document/basicDocuments/getChangeV2`, {
          caseId: this.caseId,
          documentId: this.documentId,
          myDocumentId: this.myDocumentId,
          html: this.docToSave,
        })
        .then((res) => {
          const { data } = res
          const { caseReasonChange, htmlChange } = data.data
          if (caseReasonChange) {
            this.caseReasonChange = caseReasonChange
          }
          if (htmlChange) {
            this.htmlChange = htmlChange
          }
          if (!caseReasonChange && !htmlChange) {
            return
          }
          const { htmlCode } = htmlChange
          const { caseReasonCode } = caseReasonChange
          const isReasonChanged = caseReasonCode === 1 || (caseReasonCode === 4 && caseReasonChange.recommend && caseReasonChange.recommend.length)
          const isLitigantChanged = htmlCode === 1
          // 1或5 直接调用保存接口
          const isLitigantSlientUpdate = htmlCode === 0 || htmlCode === 5
          const hasLitigant = htmlChange.changeLitigants && htmlChange.changeLitigants.length
          if (hasLitigant) {
            console.log('haslitigant真')
            this.setPickedLitigant(htmlChange.changeLitigants)
          }
          // 先判断案由是否有改动
          if (isReasonChanged) {
            console.log('isreasonchanged真')
            this.centerDialogVisible = true
            const { htmlCaseReason, recommend } = caseReasonChange
            this.pickedParseReason = htmlCaseReason || null
            this.pickedRecommendReason = recommend && recommend.length ? recommend[0] : null
            this.isLoading = false
          } else {
            if (isLitigantChanged) {
              this.centerDialogVisible = true
              console.log('handleHtmlChangeV2 展示改动弹窗')
            } else if (isLitigantSlientUpdate) {
              console.log('handleHtmlChangeV2 直接保存并更新共享信息')
              this.saveDocUseParamWithDisplayupdate(1)
            } else {
              console.log('handleHtmlChangeV2 直接保存不更新共享信息')
              this.saveDocUseParamWithDisplayupdate(0)
            }
          }

          // setTimeout(() => {
          //   this.isLoading = false
          // }, 1000)
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
        })
    },
    // 保存文书并更新共享信息
    saveDocUseParamWithDisplayupdate(displayUpdate) {
      const params = JSON.parse(JSON.stringify(this.docParams))
      params.displayUpdate = displayUpdate
      console.log('save doc data', params)
      if (this.module) {
        this.saveDocumentHtmlAndUpdateStore(params)
      } else {
        this.saveDocumentHtml(params)
      }
    },
    /** 保存文书并更新到编辑器
     * * 保存文书后再次请求接口 将拿到的文书内容更新到store中
     */
    saveDocumentHtmlAndUpdateStore(params) {
      this.isLoading = true
      console.log('保存文书并更新1111111111', this.userInfo, this.caseBasic)
      this.$axios
        .post(`${this.$base}/document/basicDocuments/saveDocumentV2`, params)
        .then((res) => {
          const { data } = res
          const { code } = data
          const { mydocumentId, dispalyRefresh } = data.data
          if (Number(code) === 200 && mydocumentId) {
            const d = JSON.parse(JSON.stringify(this.docObject))
            d.mydocumentId = mydocumentId
            d.html = this.docToSave
            this.docObject = d
          }
          // 通知原生刷新文书数据
          this.refreshNativeWrit()
          if (dispalyRefresh === 'YES') {
            if (this.$refs.topPanel && this.groupId) {
              console.log('getdocgroupdocs---')
              this.$refs.topPanel.getDocGroupDocs(this.groupId, false)
            }
          }
          this.resetChangeV2Data()
          this.resetChangePickerData()
          if (this.nextDoc) {
            console.log('has next doc, get it')
            this.getDocumentData(this.nextDoc)
          } else {
            this.isLoading = false
            this.centerDialogVisible = false
          }
          this.inEditing = false
        })
        .catch((err) => {
          console.log(err)
          if (this.nextDoc) {
            this.getDocumentData(this.nextDoc)
          }
          this.resetChangeV2Data()
          this.resetChangePickerData()
          this.isLoading = false
          this.inEditing = false
        })
      this.$axios
        .post(`${this.$pydataBase}/api/update_user_rec`, {
          user_id: this.userInfo.id,
          user_phone: this.userInfo.account,
          search_type: '判例',
          summary: this.caseBasic.causeName,
          search_text: params.html || '',
        })
        .then((res) => {
          console.loge(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 保存文书
    saveDocumentHtml(params) {
      this.isLoading = true
      console.log('保存文书并更新22222222222', this.userInfo, this.caseBasic)
      this.$axios
        .post(`${this.$base}/document/basicDocuments/saveDocumentV2`, params)
        .then((res) => {
          const { data } = res
          const { code } = data
          const { mydocumentId, dispalyRefresh } = data.data
          if (code === '200' && mydocumentId) {
            const d = JSON.parse(JSON.stringify(this.docObject))
            d.mydocumentId = mydocumentId
            d.html = this.docToSave
            // this.docObject = d
          }
          if (dispalyRefresh === 'YES') {
            if (this.$refs.topPanel && this.groupId) {
              this.$refs.topPanel.getDocGroupDocs(this.groupId, false)
            }
          }
          this.isLoading = false
          this.inEditing = false
          this.centerDialogVisible = false
        })
        .catch((err) => {
          console.log(err)
          this.resetChangeV2Data()
          this.resetChangePickerData()
          this.isLoading = false
          this.inEditing = false
        })
      this.$axios
        .post(`${this.$pydataBase}/api/update_user_rec`, {
          user_id: this.userInfo.id,
          user_phone: this.userInfo.account,
          search_type: '判例',
          summary: this.caseBasic.causeName,
          search_text: params.html || '',
        })
        .then((res) => {
          console.loge(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取文书数据
    getDocumentData(params) {
      this.isLoading = true
      this.$axios
        .post(`${this.$base}/document/basicDocuments/addInformationAndParseHtml`, {
          caseId: this.caseId,
          litigantId: params.litigantId,
          groupId: params.groupId,
          documentId: params.documentId,
          myDocumentId: params.myDocumentId,
          documentRecordId: params.documentRecordId || null,
          renderCondition: this.caseStatus === 'HIDE' ? 1 : 2,
          // renderCondition: 2
        })
        .then((response) => {
          // TODO 重置法条字数统计
          this.centerDialogVisible = false
          this.nextDoc = null
          const {
            data: { data, code },
          } = response
          if (Number(code) === 200 && data) {
            const { caseId, litigantId, modelVoList, name, groupId, myDocumentId, documentId, documentRecordId } = data
            const docObject = {
              caseId,
              documentId,
              litigantId,
              modelId: modelVoList[0].modelId,
              myDocumentId,
              documentName: name,
              documentRecordId,
              html: modelVoList[0].html,
              type: modelVoList[0].type,
              groupId,
              dataType: 'INSERT',
            }
            this.documentName = name
            this.docObject = docObject
            /*
              setTimeout(() => {
                if (groupId && !this.$route.query.groupId && !this.groupId) {
                  console.log('通知文书组匹配激活文书 仅检查documentId')
                  this.notifyTopPanelMatchDoc(true)
                  if (this.$refs.topPanel) {
                    this.$refs.topPanel.getDocGroupDocs(groupId, false)
                  }
                  this.groupId = groupId
                } else {
                  console.log(
                    '通知文书组匹配激活文书 检查documentId和myDocumentId'
                  )
                  this.notifyTopPanelMatchDoc(false)
                }
              }, 50)
              */
          } else {
            this.$notify('获取文档内容失败 请重试')
          }
        })
        .catch((err) => {
          this.nextDoc = null
          console.log(err)
        })
    },
    // 让顶部面板组件匹配文书
    notifyTopPanelMatchDoc(onlyByDocumentId) {
      if (onlyByDocumentId) {
        if (this.$refs.topPanel) {
          this.$refs.topPanel.matchActiveDocTabOnlyByDocumentId()
        }
      } else {
        if (this.$refs.topPanel) {
          this.$refs.topPanel.matchActiveDocTab()
        }
      }
      console.log('notify top panel matchdoc')
    },
    // 更新案件案由
    updateCaseReason(params) {
      return this.$axios.put(`${this.$base}/document/case/updateCause`, Qs.stringify(params))
    },
    // 文书组选中某个文书
    onDocGroupSelectDoc(doc) {
      this.module = true
      this.setNextDocEntry(doc)
      this.handleHtmlChangeV2()
    },
    // 设置下个文档入口数据
    setNextDocEntry(entry) {
      this.nextDoc = entry
    },
    // 处理获取文书数据接口入参
    handleDocEntryQuery(query) {
      const k = Object.keys(query)
      const e = {}
      k.forEach((key) => {
        const v = query[key]
        if (v && v !== 'null') {
          if (key === 'caseDocumentId') {
            e.documentId = v
          } else {
            e[key] = v
          }
        }
      })
      return e
    },
    // 创建节流检索信息方法
    createDebounceQueryFns() {
      this._debounceGetBusiness = debounce(this.getBusiness, 360)
      this._debounceGetCustomer = debounce(this.getCustomer, 360)
      this._debounceGetLawEntry = debounce(this.getLawEntry, 360)
    },
    // 处理路由参数
    async handleRouteParams() {
      /**
       * ! 注意参数有null字符串参数传过来的情况
       **/
      const { query } = this.$route

      const { myDocumentId, caseDocumentId: documentId, litigantId, groupId, fromType, documentRecordId } = query
      this.myDocumentId = myDocumentId && myDocumentId !== 'null' ? Number(myDocumentId) : null
      this.documentId = documentId && documentId !== 'null' ? Number(documentId) : null
      this.litigantId = litigantId && litigantId !== 'null' ? Number(litigantId) : null
      this.groupId = groupId && groupId !== 'null' ? Number(groupId) : null

      this.documentRecordId = documentRecordId && documentRecordId !== 'null' ? Number(documentRecordId) : null
      // 是否为普通案件
      this.isNormalCase = !(fromType === 'NOCASE')
      // 判断是否有 案件id
      if (this.caseId) {
        // 获取案件基本信息
        this.getCaseBasic()
        const p = {
          caseId: this.caseId,
          groupId: this.groupId,
          documentId: this.documentId,
          myDocumentId: this.myDocumentId,
          documentRecordId: this.documentRecordId,
        }
        const docEntry = this.handleDocEntryQuery(query)
        const params = { ...docEntry, ...p }
        this.handleEntryPage(params)

        /*
        if (this.documentId || documentRecordId) {
          const docEntry = this.handleDocEntryQuery(query)
          docEntry.caseId = this.caseId
          console.log('获取文书通过documentId')
          this.getDocumentData(docEntry)
        } else if (this.myDocumentId) {
          // 是否有 我的文书id
          console.log('获取文书通过 myDocumentId')
          this.getMyDocument(this.caseId, this.myDocumentId)
        }
        */
      }
    },
  },
  mounted() {
    this.bodyDom = document.body.offsetWidth
    // 注册自定义字号
    this.registerQuillFontSize()
    // 注册选择改变监听
    this.registerSelectionChange()
    // 注册文字改变监听
    this.registerTextChange()
  },
  async created() {
    // 创建节流检索信息方法
    this.createDebounceQueryFns()

    // 处理路由参数
    this.handleRouteParams()
  },
  destroyed() {},
  watch: {
    docObject(nv, ov) {
      // 如果文书对象dataType 为 INSERT 则将内容设置到当前编辑器
      if (nv && nv.dataType === 'INSERT') {
        this.setNewContent(nv)
      }
    },
  },
}
</script>

<style lang="stylus">
.transparent-overlay
  background transparent
.styled-dropdown-popup
  position fixed
  width 100%
  background rgba(0, 0, 0, 0.1)
  height calc(100% - 44px)
  top 44px
  z-index 998
  & .bold-dropdown, .more-menu-dropdown
    width calc(16.67% - 10px)
    margin 2px 0 10px 5px
    box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
    background #fff
    & .option, .menu
      display flex
      justify-content center
      align-items center
      height 40px
  & .more-menu-dropdown
    border-radius 4px
    width 88px
    padding 10px 0
    right 5px
    position fixed
    & .menu
      height 36px
      font-size 15px
      color #666
  & .font-dropdown
    width 100%
    background #fff
    box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
    & .options.font-family
      & .option
        width 15%
        font-size 15px
    & .options
      height 40px
      display flex
      justify-content flex-start
      & .option
        display flex
        justify-content center
        align-items center
        height 40px
        width 11%
        font-size 13px
      & .option.title
        width 44px
#editor-page
  height 100%
  width 100%
  margin 0
  padding 0
  overflow-y hidden
  & .loading-wrapper
    display flex
    justify-content center
    align-items center
    height 80px
  & .editor-container
    z-index 1
    top 0
    width 100%
    height 100%
    background #fff
    will-change transform
    transition transform 0.2s ease-in
    & .focus-btn
      width 100%
      height 30px
    & .mask, .editor-loading-wrapper
      width 100%
      height 100%
      z-index 2
      background rgba(0, 0, 0, 0.4)
      position fixed
    & .editor-loading-wrapper
      background #fff
      display flex
      display flex
      justify-content center
      align-items center
    .quill-editor
      top 43px
      height 100%
      will-change height
      transition height 0.3s
      .ql-container
        padding-top 34px
        padding-bottom 0
        border none
      .ql-container.ql-snow
        & .ql-editor
          white-space normal
          font-size 16px
          tab-size 0
          min-height 60vh
          .ql-indent-1
            padding-left 2em
          p
            em
              font-style italic
            >strong em
              font-weight bold
            span
              text-indent initial
        & .ql-hidden
          display none
  #editor-header
    border none
    padding 0
    background rgba(255, 255, 255, 1)
    position fixed
    top 0
    width 100%
    z-index 2
    & .full, .simple
      box-shadow 0px 2px 7px 1px rgba(204, 204, 204, 0.45)
      display flex
      height 43px
      flex-flow row nowrap
      justify-content space-around
      align-items center
      font-size 16px
      &.iconfont
        font-size 16px
      .toolbar-btn
        height 43px
        display flex
        height 43px
        justify-content center
        align-items center
        width (1 / 9 * 100)%
        button
          width auto
          padding 0
          svg
            display none
  & .simple-header
    box-shadow 0px 2px 7px 1px rgba(204, 204, 204, 0.45)
    display flex
    height 43px
    flex-flow row nowrap
    align-items center
    font-size 16px
    top 0
    background #fff
    position absolute
    width 100%
    z-index 3
    justify-content space-between
    .back-btn, .right-btn
      width (1 / 9 * 100)%
      height 44px
      display flex
      justify-content center
      align-items center
    .doc-name
      flex 1
      padding 0 10px
      text-align center
      font-family -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  .ql-snow.ql-toolbar
    &:after
      content none
      padding 0
  & .edit-btn
    position fixed
    bottom 20px
    right 20px
    z-index 1001
    i
      font-size 23px
      color #999
#editor-toolbar
  display height 43px
  border none
.van-action-sheet.editor-font-size-selector
  & .van-hairline--bottom
    .van-action-sheet__item
      height 44px
      line-height 44px
.ana-wrapper
  height 100%
  width 100%
  position fixed
  z-index 3
  background rgba(0, 0, 0, 0.2)
  & .bg
    height 100%
    width 100%
    position fixed
    z-index 1
  & .editor-ana
    position fixed
    margin 10px 10px 42px 10px
    z-index 2
    border-radius 4px
    width calc(100% - 20px)
    height calc(100% - 52px)
    #fb-ana
      background #fff
      height 100%
      border-radius 4px
      -webkit-overflow-scrolling touch
.van-toast.white-bg-toast
  background-color #ffffff
  color #666
.van-popup.van-popup--right
  & .reason-picker-wrapper
    background #fff
    .search-suggestion
      left 0.2666665rem
    .reason-suggestion-list
      padding 15px
      .title
        color #999999
        font-size 14px
        margin-bottom 10px
      .van-radio-group
        .van-radio
          margin-bottom 5px
          font-size 14px
.change-notice-pikcer
  & .parse-reason-sec, .rec-reason-sec, .litigant-sec
    margin-bottom 10px
    padding-bottom 10px
    & .title
      padding-left 10px
      font-size 15px
    & .picker-wrapper
      padding 10px
      .van-radio__label
        font-size 14px
    & .other-reason-picker
      margin 5px
      .text-btn
        float right
        color #409EFF
        cursor pointer
  & .litigant-sec
    margin-bottom 0px
    padding-bottom 0px
    .van-checkbox__label
      font-size 14px
</style>
<style scoped>
::v-deep .editor-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: #fff;
  opacity: 0.7;
  line-height: 100%;
  /* top: 0; */
}
.editor-loading > span {
  position: fixed;
  top: 50%;
}
</style>
