var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "editor-left-panel" },
      on: {
        "&touchstart": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchstart.apply(null, arguments)
        },
        "&touchmove": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchmove.apply(null, arguments)
        },
        "&touchend": function ($event) {
          $event.stopPropagation()
          return _vm.ontouchend.apply(null, arguments)
        },
      },
    },
    [
      _c("div", {
        staticClass: "mask",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.onMaskClick.apply(null, arguments)
          },
        },
      }),
      _c("div", { staticClass: "panel van-hairline--right" }, [
        _c("div", { staticClass: "case-info van-hairline--bottom" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.caseInfo.procedureSubject)),
          ]),
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.caseInfo.caseName)),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "all-doc-list" },
          [
            _c("fb-loading", { attrs: { loading: _vm.loadingAllDoc } }),
            _vm._l(_vm.allDocList, function (item) {
              return _c(
                "div",
                {
                  key: item.documentId,
                  class: {
                    "doc-item": true,
                    active: _vm.checkTemplateItemActive(item.documentId),
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectTemplateEntry(item)
                    },
                  },
                },
                [
                  _c("i", { class: _vm.allDocIconClass(item.type) }),
                  _c("span", { staticClass: "template-name" }, [
                    _vm._v(_vm._s(item.documentName)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "group-doc-list" },
          [
            _c("fb-loading", { attrs: { loading: _vm.loadingGroup } }),
            _vm._l(_vm.docGroup, function (item) {
              return _c(
                "div",
                {
                  key: item.groupId,
                  class: {
                    "doc-item": true,
                    active: _vm.checkGroupItemActive(item.groupId),
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.setDocGroup(item)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont icon-wiki" }),
                  _c("span", { staticClass: "template-name" }, [
                    _vm._v(_vm._s(item.groupName)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.templateList.length,
              expression: "templateList.length",
            },
          ],
          staticClass: "template-list",
          on: { click: _vm.onMaskClick },
        },
        _vm._l(_vm.templateList, function (t, i) {
          return _c(
            "div",
            {
              key: i,
              class: { template: true, active: _vm.checkTemplateActive(i) },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectTemplate(t, i, true, true)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.getTemplateName(t, i)) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }