<template>
  <div
    id="editor-left-panel"
    @touchstart.passive.stop="ontouchstart"
    @touchmove.passive.stop="ontouchmove"
    @touchend.passive.stop="ontouchend"
  >
    <div class="mask" @click.stop="onMaskClick"></div>
    <div class="panel van-hairline--right">
      <div class="case-info van-hairline--bottom">
        <span class="label">{{ caseInfo.procedureSubject }}</span>
        <span class="name">{{ caseInfo.caseName }}</span>
      </div>
      <div class="all-doc-list">
        <fb-loading :loading="loadingAllDoc" />
        <div
          v-for="item in allDocList"
          :class="{
            'doc-item': true,
            active: checkTemplateItemActive(item.documentId)
          }"
          :key="item.documentId"
          @click.stop="selectTemplateEntry(item)"
        >
          <i :class="allDocIconClass(item.type)"></i>
          <span class="template-name">{{ item.documentName }}</span>
        </div>
      </div>
      <div class="group-doc-list">
        <fb-loading :loading="loadingGroup" />
        <div
          v-for="item in docGroup"
          :class="{
            'doc-item': true,
            active: checkGroupItemActive(item.groupId)
          }"
          :key="item.groupId"
          @click.stop="setDocGroup(item)"
        >
          <i class="iconfont icon-wiki"></i>
          <span class="template-name">{{ item.groupName }}</span>
        </div>
      </div>
    </div>
    <div
      class="template-list"
      v-show="templateList.length"
      @click="onMaskClick"
    >
      <div
        :class="{ template: true, active: checkTemplateActive(i) }"
        @click.stop="selectTemplate(t, i, true, true)"
        v-for="(t, i) in templateList"
        :key="i"
      >
        {{ getTemplateName(t, i) }}
      </div>
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'

// import Qs from 'qs'

import touchMixin from '@mixins/touchHandler'
import { mapMutations } from 'vuex'

export default {
  name: 'left-panel',
  mixins: [touchMixin],
  props: {
    caseInfo: Object,
    defaultGroupId: [Number, String],
    defaultDocId: [Number, String]
  },
  data() {
    return {
      allDocList: [],
      loadingAllDoc: false,
      loadingGroup: false,
      loadingTemplate: false,
      templateList: [],
      // 草稿
      draft: {},
      isTouching: false,
      startPoint: { x: 0, y: 0 },
      currentPoint: { x: 0, y: 0 },
      templateName: '',
      seclectedTtemplateGroupId: null,
      activeTemplateListDocumentId: null,
      selectedTemplateIndex: null,
      selectedGroupItemId: null,
      // 是否需要设置默认选中文书组
      shouldSetDefaultGroup: true,
      // 是否需要设置默认选中文书
      shouldSetDefaultDoc: true,
      // 文书组
      docGroup: []
    }
  },
  computed: {
    caseId() {
      return this.$route.params.caseId || ''
    }
  },
  methods: {
    ...mapMutations('editor', ['setPushedDocument', 'setPushTime']),
    onMaskClick() {
      this.$emit('hideLeftPanel')
    },
    allDocIconClass(state) {
      if (state === 0 || state === 1) {
        return 'iconfont icon-yixie'
      } else {
        return 'iconfont icon-weixie'
      }
    },
    selectTemplateEntry(template) {
      this.setPushedDocument(template)
      this.setPushTime(new Date())
      const { documentId } = template
      this.getDocTemplates(this.caseId, documentId)
    },
    /**
     * @param templateData-模板数据 index-下标 hideLeftMenu-是否关闭左侧面板 refreshEditorContent-是否刷新编辑器内容
     **/
    selectTemplate(
      templateData,
      index,
      hideLeftPanel = false,
      refreshEditorContent = true
    ) {
      this.selectedTemplateIndex = index
      this.selectedGroupItemId = null
      const docData = {
        html: templateData.html,
        documentName: this.templateName,
        documentId: this.activeTemplateListDocumentId,
        disableEditor: true
      }
      if (refreshEditorContent) {
        this.$emit('useNewDocData', docData)
      }
      if (hideLeftPanel) {
        this.$emit('hideLeftPanel')
      }
    },
    // 触摸事件 --start--
    ontouchstart(e) {
      this.startTime = new Date()
      var t = e.touches ? e.touches[0] : e
      this.startPoint = { x: t.pageX, y: t.pageY }
    },
    ontouchmove(e) {
      const t = e.touches ? e.touches[0] : e
      const p = { x: t.pageX, y: t.pageY }
      this.currentPoint = p
      const x1 = this.startPoint.x
      const x2 = this.currentPoint.x
      const y1 = this.startPoint.y
      const y2 = this.currentPoint.y
      if (Math.abs(x1 - x2) > 2 || Math.abs(y1 - y2) > 2) {
        // 判断滑动方向
        const direction = this.getSwipeDirection(x1, x2, y1, y2)
        if (direction === 'left') {
          this.debounce(this.$emit('hideLeftPanel'), 304)
        }
      }
    },
    ontouchend(e) {
      this.isTouching = false
      // this.editor.blur()
    },
    // 触摸事件 --end--
    /**
     * @param renderCondition 是否展示模板   1-重新渲染模板生成文书 0-返回上个编辑版本  2-临时文书+文书
     **/
    getDocTemplates(
      caseId,
      documentId,
      autoSelectFirstTemplate = true,
      renderCondition = 2
    ) {
      this.loadingTemplate = true
      this.activeTemplateListDocumentId = documentId
      this.$axios({
        method: 'post',
        url: `${caseBase}/document/basicDocuments/addInformationAndParseHtml`,
        data: {
          caseId,
          documentId,
          renderCondition
        }
      })
        .then(res => {
          this.loadingTemplate = false
          if (res.data && res.data.data) {
            const { modelVoList, name } = res.data.data

            this.templateName = name
            // 取草稿
            const draft = modelVoList.filter(item => item.type === 0)
            // 取模板
            const templates = modelVoList.filter(item => item.type === 1)
            this.templateList = draft.concat(templates)
            if (autoSelectFirstTemplate) {
              setTimeout(() => {
                this.selectTemplate(this.templateList[0], 0)
              }, 100)
            }
          }
        })
        .catch(err => {
          this.loadingTemplate = false
          this.$notify('获取文书模板失败')
          Promise.reject(err)
        })
    },
    // 获取所有案件相关的文书列表数据
    getDocListData(isFristGetData = false) {
      this.loadingAllDoc = true
      this.$axios({
        method: 'get',
        url: `${caseBase}/document/basicDocuments/getDocumentsByCaseId`,
        params: {
          caseId: this.caseId
        }
      })
        .then(res => {
          this.loadingAllDoc = false
          const { data } = res.data
          if (
            this.defaultDocId &&
            this.defaultDocId !== '' &&
            this.shouldSetDefaultDoc
          ) {
            const docId = parseInt(this.defaultDocId, 0)
            const d = data.filter(g => g.documentId === docId)
            const o = data.filter(g => g.documentId !== docId)
            this.allDocList = [...d, ...o]
            setTimeout(() => {
              this.activeTemplateListDocumentId = docId
              if (isFristGetData) {
                // 不自动选择第一个文书
                this.getDocTemplates(this.caseId, docId, false)
              } else {
                // 自动选择第一个文书
                this.getDocTemplates(this.caseId, docId)
              }
              if (this.defaultGroupId === '') {
                this.selectTemplate(d[0], 0, false, false)
              }
            }, 100)
            this.shouldSetDefaultGroup = false
          } else {
            this.allDocList = data
          }
        })
        .catch(err => {
          this.loadingAllDoc = false
          this.$notify('获取相关文书失败')
          Promise.reject(err)
        })
    },
    // 获得汉字 数字 如 一二三
    getNumberInChinese(num) {
      const dict = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
      return dict[num]
    },
    // 检查模板列表条目是否选中
    checkTemplateItemActive(id) {
      if (this.activeTemplateListDocumentId === id) {
        return true
      } else {
        return false
      }
    },
    // 检查模板条目是否选中
    checkTemplateActive(index) {
      if (this.selectedTemplateIndex === index) {
        return true
      } else {
        return false
      }
    },
    // 检查文书组条目是否选中
    checkGroupItemActive(id) {
      if (this.selectedGroupItemId === id) {
        return true
      } else {
        return false
      }
    },
    // 获取文书组列表
    getDocGroup() {
      this.loadingGroup = true
      this.$axios({
        method: 'get',
        url: `${caseBase}/document/basicGroup/getBasicGroupByCaseId`,
        params: {
          caseId: this.caseId
        }
      })
        .then(res => {
          this.loadingGroup = false
          const { data } = res.data
          if (
            this.defaultDocId &&
            this.defaultDocId !== '' &&
            this.shouldSetDefaultDoc
          ) {
            const gId = parseInt(this.defaultGroupId, 0)
            const dGroup = data.filter(g => g.groupId === gId)
            const oGroup = data.filter(g => g.groupId !== gId)
            this.docGroup = [...dGroup, ...oGroup]
            setTimeout(() => {
              this.selectedGroupItemId = gId
            }, 304)
            this.shouldSetDefaultGroup = false
          } else {
            this.docGroup = data
          }
        })
        .catch(err => {
          this.loadingGroup = true
          this.$notify('获取文书组失败')
          console.log('获取文书组失败', err)
          console.log(this.caseId)
          Promise.reject(err)
        })
    },
    // 设置文书组
    setDocGroup(d) {
      this.selectedGroupItemId = d.groupId
      // 清除文书模板选中状态
      this.activeTemplateListDocumentId = null
      this.selectedTemplateIndex = undefined
      this.templateList = []
      // 通知父组件设置文书组信息
      this.$emit('setGroupEntry', { ...d, caseId: this.caseId })
    },
    getTemplateName(t, i) {
      if (t.type === 0) {
        return '临时文件'
      } else if (t.type === 1) {
        const hasDraft = this.templateList.filter(item => item.type === 0)
        if (hasDraft.length) {
          const index = i - 1
          const num = this.getNumberInChinese(index)
          return `模板${num}`
        } else {
          const num = this.getNumberInChinese(i)
          return `模板${num}`
        }
      }
    },
    // 刷新模板列表状态 一般是编辑了文书之后执行这个操作
    updateTemplateState(documentId) {
      // 1 更新模板数据
      const updateTemplateState = false
      this.getDocTemplates(this.caseId, documentId, updateTemplateState)
      // 2 改变模板列表 **是否下载**状态
      const newDocList = []
      this.allDocList.forEach(item => {
        if (item.documentId === documentId) {
          const obj = item
          obj.type = 0
          newDocList.push(obj)
        } else {
          newDocList.push(item)
        }
      })
      this.allDocList = newDocList
    }
  },
  created() {
    this.getDocListData(true)
    this.getDocGroup()
  }
}
</script>

<style lang="stylus">
#editor-left-panel
  height 100%
  width 100%
  z-index 108
  position fixed
  display flex
  will-change transform
  transition transform 0.32s ease-in-out
  user-select none
  .mask
    position fixed
    height 100%
    width 100%
    background rgba(0, 0, 0, 0.15)
  .template-list
    z-index 2
    height 100%
    width 28%
    background transparent
    display flex
    flex-direction column
    align-items center
    justify-content flex-start
    padding-top 64px
    .template
      z-index 3
      width 80%
      background #ffffff
      border-radius 4px
      height 100px
      margin-bottom 20px
      display flex
      justify-content center
      align-items center
      font-family BigYoungBoldGB20
      font-weight 600
      font-size 16px
      writing-mode vertical-rl
      color #222
      border 2px solid #ffffff
      &.active
        border 2px solid #4B9EFB
        color #4B9EFB
  .panel
    z-index 2
    height 100%
    width 72%
    background #ffffff
    .case-info
      height 43px
      display flex
      justify-content center
      align-items center
      font-size 15px
      font-family PingFangSC-Medium
      font-weight 500
      .label
        color #4B9EFB
        margin 0 10px
      .name
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
    .all-doc-list, .group-doc-list
      height calc(((100% - 43px) / 2))
      overflow-y scroll
      overflow-x hidden
      padding 5px 0
      user-select none
      width 100%
      -webkit-overflow-scrolling touch
      border-bottom 2px solid #ccc
      .doc-item
        height 36px
        text-align left
        font-size 15px
        padding 0
        display flex
        align-items center
        width 100%
        user-select none
        font-family PingFangSC
        font-weight 400
        color rgba(51, 51, 51, 1)
        i
          margin 0 6px 0 10px
          font-size 15px
        .template-name
          padding-right 10px
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
        &.active
          background #ccc
    .group-doc-list
      height calc(((100% - 63px) / 2))
      border-bottom none
</style>
