var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "editor-loading",
        attrs: { vertical: "", color: "#1989FA" },
        on: {
          touchmove: function ($event) {
            $event.preventDefault()
          },
        },
      }),
      _c(
        "div",
        { attrs: { id: "editor-page" } },
        [
          _vm.showPreviewModal
            ? _c("preview-box", {
                attrs: { htmlStr: _vm.docToSave },
                on: { closePreview: _vm.exitPreview },
              })
            : _vm._e(),
          _vm.showAnalysisBox
            ? _c("div", { staticClass: "ana-wrapper" }, [
                _c("div", {
                  staticClass: "bg",
                  on: {
                    click: function ($event) {
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      return _vm.hideAnalysis.apply(null, arguments)
                    },
                  },
                }),
                _c("div", { staticClass: "editor-ana" }, [_c("ana-box")], 1),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMoreMenu,
                  expression: "showMoreMenu",
                },
              ],
              staticClass: "styled-dropdown-popup",
              on: { click: _vm.hideMoreMenuPopup },
            },
            [
              _c("div", { staticClass: "more-menu-dropdown" }, [
                _c(
                  "div",
                  {
                    staticClass: "menu",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.preview.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
                _vm.canCreateCase
                  ? _c(
                      "div",
                      { staticClass: "menu", on: { click: _vm.createCase } },
                      [_vm._v("建案")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "menu", on: { click: _vm.showAnalysis } },
                  [_vm._v("分析")]
                ),
                _vm.canDelete
                  ? _c(
                      "div",
                      {
                        staticClass: "menu",
                        on: { click: _vm.onDeleteMyDocument },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c("left-panel", {
            ref: "leftPanel",
            style: _vm.leftPanelStyle,
            attrs: {
              defaultGroupId: _vm.groupId,
              defaultDocId: _vm.documentId,
              caseInfo: _vm.caseBasic,
            },
            on: {
              useNewDocData: _vm.switchToDocInCurrGroup,
              setGroupEntry: _vm.setDocGroup,
              hideLeftPanel: _vm.handelHideLeftPanel,
            },
          }),
          _c(
            "div",
            {
              attrs: { id: "editor-header" },
              on: {
                touchstart: function ($event) {
                  $event.stopPropagation()
                  return _vm.ontouchstart.apply(null, arguments)
                },
                touchmove: function ($event) {
                  $event.stopPropagation()
                  return _vm.ontouchmove.apply(null, arguments)
                },
                touchend: function ($event) {
                  $event.stopPropagation()
                  return _vm.ontouchend.apply(null, arguments)
                },
              },
            },
            [
              _c("top-panel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.inEditing,
                    expression: "!inEditing",
                  },
                ],
                ref: "topPanel",
                attrs: {
                  docGroupId: _vm.docGroupId,
                  caseId: _vm.caseId,
                  documentName: _vm.documentName,
                  defaultGroupId: _vm.receivedGroupId,
                  defaultDocumentId: _vm.defaultDocumentId,
                  activeDoc: _vm.docObject,
                },
                on: {
                  showAna: _vm.showAnalysis,
                  back: _vm.back,
                  toggleMoreMenu: _vm.toggleMoreMenuPopup,
                  setCreateCaseDoc: _vm.setCreateCaseDocText,
                  updateDoc: _vm.updateDocDataSilent,
                  getDocData: _vm.getDocumentData,
                  selectDoc: _vm.onDocGroupSelectDoc,
                  showMask: function ($event) {
                    _vm.showMask = true
                  },
                  hideTopPanel: _vm.hideTopPanelAction,
                  askSave: _vm.autoSave,
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inEditing,
                      expression: "inEditing",
                    },
                  ],
                  staticClass: "full",
                },
                [
                  _c("div", { staticClass: "toolbar-btn" }, [
                    _c("div", {
                      staticClass: "iconfont icon-back",
                      on: { click: _vm.back },
                    }),
                  ]),
                  _c("div", { staticClass: "toolbar-btn" }, [
                    _c("div", {
                      staticClass: "iconfont icon-editor-bold",
                      on: { touchend: _vm.setBold },
                    }),
                  ]),
                  _c("div", { staticClass: "toolbar-btn" }, [
                    _c("div", {
                      staticClass: "iconfont icon-editor-italic",
                      on: { touchend: _vm.setItalic },
                    }),
                  ]),
                  _c("div", { staticClass: "toolbar-btn" }, [
                    _c("div", {
                      staticClass: "iconfont icon-editor-underline",
                      on: { touchend: _vm.setUnderline },
                    }),
                  ]),
                  _c("div", { staticClass: "toolbar-btn" }, [
                    _c("div", { on: { touchend: _vm.setFontSize } }, [
                      _c("i", {
                        staticClass: "iconfont icon-editor-font-size",
                      }),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "toolbar-btn", on: { touchend: _vm.redo } },
                    [_c("i", { staticClass: "iconfont icon-editor-redo" })]
                  ),
                  _c(
                    "div",
                    { staticClass: "toolbar-btn", on: { touchend: _vm.undo } },
                    [_c("i", { staticClass: "iconfont icon-editor-undo" })]
                  ),
                  _c(
                    "div",
                    { staticClass: "toolbar-btn", on: { click: _vm.save } },
                    [_c("i", { staticClass: "iconfont icon-editor-save" })]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "editor-container",
              on: {
                touchstart: function ($event) {
                  $event.stopPropagation()
                  return _vm.onEtouchstart.apply(null, arguments)
                },
                touchmove: function ($event) {
                  $event.stopPropagation()
                  return _vm.onEtouchmove.apply(null, arguments)
                },
                touchend: function ($event) {
                  $event.stopPropagation()
                  return _vm.onEtouchend.apply(null, arguments)
                },
              },
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showMask,
                    expression: "showMask",
                  },
                ],
                staticClass: "mask",
                on: {
                  touchstart: function ($event) {
                    $event.stopPropagation()
                    return _vm.onMaskTouchstart.apply(null, arguments)
                  },
                  touchmove: function ($event) {
                    $event.stopPropagation()
                    return _vm.onMaskTouchmove.apply(null, arguments)
                  },
                  touchend: function ($event) {
                    $event.stopPropagation()
                    return _vm.onMaskTouchend.apply(null, arguments)
                  },
                  click: _vm.hideMask,
                },
              }),
              _c(
                "quill-editor",
                {
                  ref: "quillEditor",
                  style: _vm.editorWrapperStyle,
                  attrs: { content: _vm.content, options: _vm.editorOption },
                  on: {
                    change: function ($event) {
                      return _vm.onEditorChange($event)
                    },
                    blur: function ($event) {
                      return _vm.onEditorBlur($event)
                    },
                    focus: function ($event) {
                      return _vm.onEditorFocus($event)
                    },
                    ready: function ($event) {
                      return _vm.onEditorReady($event)
                    },
                  },
                },
                [
                  _c("div", {
                    attrs: { slot: "toolbar", id: "editor-toolbar" },
                    slot: "toolbar",
                  }),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.focusBtn.show,
                      expression: "focusBtn.show",
                    },
                  ],
                  style: `position: relative;`,
                },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "focus-btn",
                      attrs: { type: "info", size: "small" },
                      on: { click: _vm.insert },
                    },
                    [_vm._v("插入")]
                  ),
                ],
                1
              ),
              _vm.showSuggestionPanel
                ? _c("suggestion-panel", {
                    attrs: { height: _vm.suggestionPanelHeight },
                    on: { useNewText: _vm.useSuggestion },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("van-action-sheet", {
            staticClass: "editor-font-size-selector",
            attrs: { actions: _vm.fontSizeActions },
            on: { select: _vm.onSelectFontSize },
            model: {
              value: _vm.showFontSizePicker,
              callback: function ($$v) {
                _vm.showFontSizePicker = $$v
              },
              expression: "showFontSizePicker",
            },
          }),
          _c(
            "van-popup",
            {
              attrs: { position: "right", overlay: false },
              model: {
                value: _vm.centerDialogVisible,
                callback: function ($$v) {
                  _vm.centerDialogVisible = $$v
                },
                expression: "centerDialogVisible",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "reason-picker-wrapper",
                  style: _vm.reasonPickerStyle,
                },
                [
                  _c(
                    "div",
                    { staticClass: "change-notice-pikcer" },
                    [
                      _c("reason-picker", {
                        ref: "reasonPicker",
                        attrs: { showConfirmBtn: true },
                        on: {
                          change: _vm.handleReasonChange,
                          confirmSelect: _vm.handleReasonSelect,
                        },
                      }),
                      _vm.caseReasonChange && _vm.caseReasonChange.caseReasonMsg
                        ? _c("div", { staticClass: "parse-reason-sec" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("请选择案件适用的案由："),
                            ]),
                            _c(
                              "div",
                              { staticClass: "picker-wrapper" },
                              [
                                _c(
                                  "van-radio-group",
                                  {
                                    model: {
                                      value: _vm.pickedParseReason,
                                      callback: function ($$v) {
                                        _vm.pickedParseReason = $$v
                                      },
                                      expression: "pickedParseReason",
                                    },
                                  },
                                  [
                                    _c(
                                      "van-radio",
                                      {
                                        attrs: {
                                          name: _vm.caseReasonChange
                                            .caseCaseReason,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.caseReasonChange.caseCaseReason
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "van-radio",
                                      {
                                        attrs: {
                                          name: _vm.caseReasonChange
                                            .htmlCaseReason,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.caseReasonChange
                                              .htmlCaseReason + "（当前文书）"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.caseReasonChange.recommend &&
                      _vm.caseReasonChange.recommend.length
                        ? _c("div", { staticClass: "rec-reason-sec" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("请选择案件适用的案由："),
                            ]),
                            _c(
                              "div",
                              { staticClass: "picker-wrapper" },
                              [
                                _c(
                                  "van-radio-group",
                                  {
                                    model: {
                                      value: _vm.pickedRecommendReason,
                                      callback: function ($$v) {
                                        _vm.pickedRecommendReason = $$v
                                      },
                                      expression: "pickedRecommendReason",
                                    },
                                  },
                                  _vm._l(
                                    _vm.caseReasonChange.recommend,
                                    function (r) {
                                      return _c(
                                        "van-radio",
                                        { key: r, attrs: { name: r } },
                                        [_vm._v(_vm._s(r) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.htmlChange.changeLitigants &&
                      _vm.htmlChange.changeLitigants.length &&
                      _vm.htmlChange.htmlCode === 1
                        ? _c("div", { staticClass: "litigant-sec" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("请选择案件当事人："),
                            ]),
                            _c(
                              "div",
                              { staticClass: "picker-wrapper" },
                              [
                                _c(
                                  "van-checkbox-group",
                                  {
                                    staticClass: "no-flex",
                                    model: {
                                      value: _vm.pickedLitigant,
                                      callback: function ($$v) {
                                        _vm.pickedLitigant = $$v
                                      },
                                      expression: "pickedLitigant",
                                    },
                                  },
                                  _vm._l(_vm.allLitigants, function (p, i) {
                                    return _c(
                                      "div",
                                      { key: i },
                                      [
                                        _c(
                                          "van-checkbox",
                                          {
                                            attrs: {
                                              name: _vm.calcLitigantId(p),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.calcLitigantName(p))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }